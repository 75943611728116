:root {
  color-scheme: light dark; /* both supported */
}

body {
  height: 100%;
  background-color: $background-color;
}

#content-container {
  height: 100%;
  width: 100%;

  @include media-query(medium-screens) {
    width: calc(100% - #{$sidebar-width});
    margin-left: $sidebar-width;
  }

}

#content {
  width: $content-width;
  padding-bottom: $padding-top-large / 2;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @include media-query(medium-screens) {
    margin-left: 0;
    margin-right: 0;
  }

  @include media-query(handhelds) {
    max-width: 100%;
    overflow-x: hidden;
    width: 100%;
    // padding-bottom: $padding-top-handheld / 2;
    margin: 0;
  }

  p {
    width: $content-inner-width;
    display: block;
    margin: 0 auto 0 auto;

    @include media-query(handhelds) {
      max-width: $content-inner-width;
      width: auto;
      padding-left: $spacing-unit / 2;
      padding-right: $spacing-unit / 2;
    }
  }

  img {
      max-width: $content-width;
      width: $content-width;
      margin: $spacing-unit 0 $spacing-unit 0;

      &.ios {
        margin: 40pt auto;
      }

      &.npaudiostream {
        margin: $spacing-unit * 0.75 auto $spacing-unit * 0.75 auto;
      }

      @include media-query(handhelds) {
        width: calc(100% - 28pt);
        max-width: $content-inner-width;
        display: block;
        padding-left: $spacing-unit / 2;
        padding-right: $spacing-unit / 2;
        margin: $spacing-unit auto $spacing-unit auto;
      }

    }

    .action {
        margin-top: 32pt;
    }

}


header {
  position: relative;
  min-height: 64px;
  height: $padding-top-large;
  width: 100%;
  text-align: center;

  @include media-query(medium-screens) {
    width: $content-width;
  }

  @include media-query(handhelds) {
    height: $padding-top-handheld;
    width: 100%;
  }
}


.header-image {
  $header-image-height: 96px;
  position: absolute;
  height: $header-image-height;
  margin: 0 auto 0 auto;
  left: 0;
  right: 0;
  bottom: $padding-top-handheld / 4;

  @include media-query(handhelds) {
    height: $header-image-height * 2 / 3;
  }
}

#menu-toggle {
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  text-decoration: none;
  color: black;
  line-height: 64px;

  width: 64px;
  height: 64px;

  display: none;

  a {

      &:visited {
        color: black;
      }

      &:hover {
        color: black;
      }

  }

  @include media-query(handhelds) { display: block; }

}

// Hack to make the Zoom and Slidable libraries play nice

.zoom-overlay-open {
  [canvas=container] {
    z-index: 10;
  }
}

.zoom-overlay {
  z-index: 1;
}
