#sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: $sidebar-width;
    padding-top: $padding-top-large;
    padding-bottom: $spacing-unit;
    background-color: $background-color;
    z-index: 10;
    border-right: 0;

    @include media-query(handhelds) {
      padding-top: $padding-top-handheld;
      z-index: 0;
      border-right: 1px solid $inactive-text-color;

      @include on-retina() {
        border-right: 0.5px solid $inactive-text-color;
      }
    }

    // Slidebar hack
    -webkit-transform: none;
    transform: none;
    display: inline;

    ul {
      padding: 0;
      margin-left: $spacing-unit * 1.5;

      li {
        list-style-type: none;
        cursor: default;

        a {
          color: $grey-color;
          text-decoration: none;
          cursor: default;

          &:visited {
            color: $grey-color;
            cursor: default;
          }

          &:hover {
            color: $text-color;
            cursor: default;
          }

          &.active {
            color: $text-color;
          }
        }
      }
    }

    #sidebar-projects,
    #sidebar-social,
    #sidebar-hire {
      margin-top: $spacing-unit;
    }

    #sidebar-social {
      cursor: default;

      li {
        display: inline;
        padding-right: 8px;
        cursor: default;

        a {
          cursor: default;
        }

        a:hover.dribbble {
          color: $dribbble-color;
        }

        a:hover.twitter {
          color: $twitter-color;
        }

        a:hover.discord {
          color: $discord-color;
        }

      }
    }
}
