@charset "utf-8";

@font-face {
	font-family: 'Proxima Nova';
	src: url('/fonts/ProximaNova-Semibold.otf');
	font-weight: normal;
	font-style: normal;
}


// Our variables
$base-font-family:			"Proxima Nova", sans-serif;
$base-font-size:			0.9em;
$base-font-weight:			400;
$base-line-height:			1.5;

$spacing-unit:     			32px;
$padding-top-large:			26.8vh;
$padding-top-handheld:		22vh;

$background-color:			white;
$background-color-dark:		#131415;
$text-color:				black;
$text-color-dark:			white;
$grey-color:				#9e9e9e;
$inactive-text-color:		#9e9e9e;
$inactive-text-color-dark:	#5e5e5e;
$highlight-color:			#2a7ae2;
$dribbble-color:			#ea4c89;
$twitter-color:				#55acee;
$discord-color:				#6063f2;

// Width of the content area
$sidebar-width:				$spacing-unit * 8;
$content-width:				640px;
$content-inner-width:		540px;

// Media Queries
$break-small:				$sidebar-width + $content-width;
$break-large:				$sidebar-width * 2 + $content-width;



@mixin media-query($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $break-small) { @content; }
  }
  @else if $media == medium-screens {
    @media only screen and (min-width: $break-small + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == wide-screens {
    @media only screen and (min-width: $break-large) { @content; }
  }
}

// Example usage
// @include media-query(handhelds) { width: 100% ;}
// @include media-query(medium-screens) { width: 125px; }
// @include media-query(wide-screens) { float: none; }

@mixin on-retina() {
	@media (-webkit-min-device-pixel-ratio: 2) {
	    @content;
	}
}

//Import partials

@import
	"fa/fontawesome.scss",
	"fa/brands.scss",
	"fa/regular.scss",
	"fa/solid.scss",
	"general",
	"utils",
	"sidebar",
	"default-layout",
	"dark-mode"
;
