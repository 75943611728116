@media (prefers-color-scheme: dark) {

  body {
    background-color: $background-color-dark;
    color: white;
  }

  #content-container {
    background-color: $background-color-dark;
  }

  #sidebar {
    background-color: $background-color-dark;
  }

  #menu-toggle {
    color: white;
  }

  a {

      &:hover {
          color: white;
      }

  }

    #sidebar {

        ul {

            li {

                a {

                    color: $inactive-text-color-dark;

                    &:visited {
                        color: $inactive-text-color-dark;
                    }

                    &:hover {
                        color: $text-color-dark;
                    }

                    &.active {
                        color: $text-color-dark;
                    }

                }

            }

        }

    }


}
